<template>
  <v-card>
    <v-card-title
      class="headline grey lighten-2"
      primary-title
    >
      {{roleUsersLabel}}

      <v-spacer></v-spacer>

      <v-btn @click="emitClose()">
        close
      </v-btn>
    </v-card-title>
    <v-card-text class="card-text-roleuser">
      <v-row>
        <v-col>
          <div>
            <v-text-field label="search" v-model="search">
            </v-text-field>
          </div>
          <div class="table-role-div">
            <v-data-table
              :items="allRoles"
              :loading="loadingAllRoles"
              :search="search"
              :headers="headers"
              :footer-props="{
                itemsPerPageOptions: [{text: 'All','value':-1}, 20,50,100]
              }"
            >
              <template v-slot:item="props">
                <tr>
                  <td>{{ props.item.role }}</td>
                  <td>
                    <v-btn icon @click="getUsersWithTheRole(props.item.role)">
                      <v-icon>
                        supervisor_account
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-col>
        <v-col>
          <h2>{{currentRole}}</h2>
          <div class="table-user-div">
            <v-data-table
              :items="usersWithTheRole"
              :loading="loadingUsers"
              :footer-props="{
                itemsPerPageOptions: [{text: 'All','value':-1}, 20,50,100]
              }"
            >
              <template v-slot:item="props">
                <tr>
                  <td>{{ props.item.mail }}</td>
                  <td>
                    <v-btn :disabled="isDisabledDelete(props.item)" icon @click="emitDelete(props.item.user_id)">
                      <v-icon>
                        delete
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import store from '../../../store'
import { common } from '../../../store/modules/common'

if (!store.state.common) store.registerModule('common', common)

export default {
  name: 'RoleUsers',
  data: function () {
    return {
      allRoles: [],
      loadingAllRoles: false,
      loadingUsers: false,
      usersWithTheRole: [],
      currentRole: '',
      search: '',
      headers: [
        { text: 'role', value: 'role' }
      ]
    }
  },
  mounted () {
    this.getAllRoles()
  },
  methods: {
    isDisabledDelete (user) {
      const userIdsWithTheRole = this.usersWithTheRole.map(item => item.user_id)
      const currentUser = this.$store.getters.getCurrentUserId
      return userIdsWithTheRole.indexOf(currentUser) === -1
    },
    emitClose () {
      this.$emit('close')
    },
    emitDelete (userId) {
      this.$emit('delete-rights', [this.currentRole], [userId])
      const removedUser = this.usersWithTheRole.filter(item => item.user_id === userId)
      const indexRemovedUser = this.usersWithTheRole.indexOf(removedUser[0])
      this.usersWithTheRole.splice(indexRemovedUser, 1)
    },
    async getAllRoles () {
      this.loadingAllRoles = true
      const result = await this.$apiCaller.getAllRoles()
      this.allRoles = []

      if (this.$apiCaller.isResponseError(result, true)) {
        console.warn('Error or call cancel when calling the api all roles')
        if (!this.$apiCaller.isCancelError(result)) {
          this.$store.commit('setErrorMessageWithResponse', result)
        }
      } else {
        this.allRoles = result.data.map(item => {
          return {
            role: item
          }
        })
      }
      this.loadingAllRoles = false
    },
    async getUsersWithTheRole (role) {
      this.currentRole = role
      this.loadingUsers = true
      this.usersWithTheRole = []
      const result = await this.$apiCaller.getRoleUsers(role)
      if (this.$apiCaller.isResponseError(result)) {
        console.warn('Error when calling the api role user')
        this.$store.commit('setErrorMessageWithResponse', result)
      } else {
        this.usersWithTheRole = result.data
      }
      this.loadingUsers = false
    }
  },
  computed: {
    roleUsersLabel () {
      return 'Role <> Users'
    }
  }
}
</script>

<style scoped>
.card-text-roleuser {
  height: 80%;
}

.table-user-div,
.table-role-div {
  height: 75vh;
  overflow: auto;
}
</style>
