<template>
  <v-card>
    <v-card-title
      class="headline grey lighten-2"
      primary-title
    >
      Role By Type
      <v-spacer></v-spacer>

      <v-btn @click="emitClose()">
        close
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row row>
        <v-col cols="6">
          <template v-for="(item, index) in rolesByTypeItems">
            <v-list v-bind:key="index">
              <v-list-group v-bind:key="index" v-model="item.active">
                <template v-slot:activator>
                  <v-list-item class="grey lighten-2">
                    <v-list-item-content>
                      <v-list-item-title> {{item.title}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-list-item-content v-for="(role, index) in item.roles" v-bind:key="index">
                  {{role}}
                </v-list-item-content>
              </v-list-group>
            </v-list>
          </template>
        </v-col>
        <v-col cols="6">
          <template v-for="(item, index) in groupRoleItems">
            <v-list v-bind:key="index">
              <v-list-group v-bind:key="index" v-model="item.active">
                <template v-slot:activator>
                  <v-list-item class="grey lighten-2">
                    <v-list-item-content>
                      <v-list-item-title> {{item.title}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-list-item-content v-for="(role, index) in item.roles" v-bind:key="index">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        {{role}}
                      </div>
                    </template>
                    <span v-html="getRolesTooltip(role)">
                    </span>
                  </v-tooltip>
                </v-list-item-content>
              </v-list-group>
            </v-list>
          </template>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'RoleByType.vue',
  data: function () {
    return {
      rolesByType: {},
      groupRole: {
        // to update manually
        DEBUGGER_RIGHT: [
          'BASIC',
          'READ_SURCOUCHE',
          'WRITE_SURCOUCHE',
          'ALERTS_READ',
          'ALERTS_WRITE',
          'USERS_READ',
          'READ_KEYSTONE',
          'WRITE_KEYSTONE',
          'CUICUI_DATA',
          'SPLIT_AUDIENCE_READ',
          'SPLIT_AUDIENCE_WRITE',
          'MANAGE_DEMO',
          'AUTOPLUG_READ',
          'AUTOPLUG_WRITE',
          'CHECK_PREMEP'
        ],
        AM_RIGHTS: [
          'BASIC',
          'READ_SURCOUCHE',
          'WRITE_SURCOUCHE',
          'ALERTS_READ',
          'ALERTS_WRITE',
          'USERS_READ',
          'READ_KEYSTONE',
          'WRITE_KEYSTONE',
          'CUICUI_DATA',
          'SPLIT_AUDIENCE_READ',
          'MANAGE_DEMO',
          'AUTOPLUG_READ',
          'CHECK_PREMEP'
        ],
        SALE_RIGHTS: [
          'BASIC',
          'READ_SURCOUCHE',
          'USERS_READ',
          'READ_KEYSTONE',
          'CUICUI_DATA',
          'MANAGE_DEMO',
          'AUTOPLUG_READ',
          'CHECK_PREMEP'
        ],
        SETTUPPER_RIGHTS: [
          'SERVICE_ACCOUNT_READ',
          'SERVICE_ACCOUNT_WRITE',
          'USERS_WRITE',
          'USERS_READ',
          'PROJECT_CONFIG_READ',
          'PROJECT_CONFIG_WRITE',
          'AUTOPLUG_READ',
          'CHECK_PREMEP',
          'THIRD_PARTY_UI'
        ],
        ADMIN_AND_FINANCE_RIGHTS: [
          'BASIC',
          'READ_KEYSTONE',
          'WRITE_KEYSTONE',
          'AUTOPLUG_READ'
        ]
      }
    }
  },
  mounted () {
    this.getRolesByType()
  },
  methods: {
    emitClose () {
      this.$emit('close')
    },
    async getRolesByType () {
      this.loadingAllRoles = true
      const result = await this.$apiCaller.getAllRoles(true)
      this.rolesByType = []
      if (this.$apiCaller.isResponseError(result, true)) {
        console.warn('Error or call cancel when calling the api role by type')
        if (!this.$apiCaller.isCancelError(result)) {
          this.$store.commit('setErrorMessageWithResponse', result)
        }
      } else {
        this.rolesByType = result.data
        this.$store.commit('setRoleByType', this.rolesByType)
      }
      this.loadingAllRoles = false
    },
    getRolesTooltip (role) {
      return role in this.rolesByType ? this.$commonUtils.htmlEntitiesList(this.rolesByType[role]).join('<br>') : ''
    }
  },
  computed: {
    roleByTypeFiltered () {
      let { ALL_RIGHT, AM_RIGHTS, DEBUGGER_RIGHT, SALE_RIGHTS, ADMIN_AND_FINANCE_RIGHTS, ...rest } = this.rolesByType
      return rest
    },
    rolesByTypeItems () {
      return Object.keys(this.roleByTypeFiltered).map((keys) => {
        return {
          title: keys,
          roles: this.roleByTypeFiltered[keys],
          active: true
        }
      })
    },
    groupRoleItems () {
      return Object.keys(this.groupRole).map((keys) => {
        return {
          title: keys,
          roles: this.groupRole[keys],
          active: true
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
