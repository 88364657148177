<template>
    <v-container justify-center d-flex>
      <v-col cols="3" v-for="dsp in dsps" v-bind:key="dsp" style="width: 20%;">
        <v-card
          class="mx-auto"
          max-width="200"
          width="100%"
          v-on:click="changeDSP(dsp)"
          style="cursor: pointer;"
          :class="[dsp===selectDSP ? 'selected-dsp' : '']"
        >
          <v-img
            class="white--text align-end"
            height="100%"
            width="100%"
            :src="images[dsp]"
          >
          </v-img>
          <v-card-title class="justify-center">{{ dsp }}</v-card-title>
        </v-card>
      </v-col>
    </v-container>
</template>

<script>
export default {
  name: 'SelectDSP',
  data: function () {
    return {
      dsps: [
        'appnexus',
        'dv360',
        'ttd',
        'mediamath'
      ],
      images: {
        dv360: require('../../assets/dbm.png'),
        appnexus: require('../../assets/appnexus.png'),
        ttd: require('../../assets/thetradedesk.png'),
        mediamath: require('../../assets/mediamath.png')
      },
      selectDSP: null
    }
  },
  methods: {
    changeDSP: function (dsp) {
      this.selectDSP = dsp
    }
  },
  watch: {
    selectDSP: function (newValue, oldValue) {
      this.$emit('dsp', newValue)
    }
  },
  beforeMount: function () {
    this.selectDSP = this.dsps[0]
  }
}
</script>

<style scoped>
  .selected-dsp {
    border-left: 5px solid green !important;
  }
</style>
