<template>
  <v-row wrap class="user-panel-layout full-screen-height">
    <v-col cols="12">
      <div class="py-2 pl-1 font-weight-bold">
        User Panel {{selectedId}}
      </div>
      <v-divider></v-divider>
    </v-col>
    <v-col cols="12">
      <v-row wrap>
        <v-col cols="12" class="px-2">
          <v-autocomplete
            label="select roles to add"
            :items="currentUserRoles"
            multiple
            v-model="rightsToAdd"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" class="px-2">
          <v-row wrap>
            <v-col cols="6">
              <v-btn :disabled="disableAdd" color="primary" @click="emitProcessAddRights()" :loading="isProcessingAdd">
                {{ isBucketEdit ? 'Add Buckets' : 'Add' }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn :disabled="disableUpdateStatus" color="primary" @click="emitUpdateStatus()">
                Update status
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" v-if="displaySingleUserPanel" class="px-2">
      <v-text-field
        label="search"
        v-model="searchRole"
      >
      </v-text-field>
    </v-col>
    <v-col cols="12">
      <v-btn @click="deleteRoles" :disabled="disabledDeleteRoles" :loading="isProcessingDelete">
        DELETE ROLE(S)
      </v-btn>
    </v-col>
    <v-col cols="12" v-if="displaySingleUserPanel" class="table-role-overflow">
      <div v-if="isLoadingRole" class="text-center progress-div">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        >
        </v-progress-circular>
      </div>

      <v-data-table
        v-if="!isLoadingRole"
        :items="currentRoles"
        :headers="headersRole"
        :search="searchRole"
        hide-default-footer
        v-model="selectedRights"
        show-select
        item-key="role"
        :footer-props="{
          itemsPerPageOptions: [{text: 'All','value':-1}]
        }"
      >
        <template v-slot:item="props">
          <tr>
            <td>
              <v-checkbox
                v-model="props.isSelected"
                primary
                hide-details
                :disabled="isDisabledActionCheckBox(props.item.role)"
              ></v-checkbox>
            </td>
            <td>
                <v-tooltip bottom :disabled="!roleIsInheritedFromStatus(props.item.role)">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ props.item.role }} <v-icon class="pl-2" small v-if="roleIsInheritedFromStatus(props.item.role)">info</v-icon></span>
                  </template>
                  <span>
                    Can not remove this role.
                    This role is inherited from {{getRoleIsInheritedFrom(props.item.role)}}.
                    If you want to remove this role, you must remove the status of the user.
                  </span>
                </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
    <v-col v-else-if="displayBucketEditPanel">
      <v-row wrap>
        <v-col cols="12">
          Bucket Edit :
        </v-col>
        <v-col cols="12">
          Selected :
          <div class="selected-bucket-div">
            <v-list v-for="(item, index) in selected" :key="index">
              {{item.mail}}
            </v-list>
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" v-else>
      <div class="text-center pa-12 font-weight-bold">
        Select a user to edit or a buckets of user
      </div>
    </v-col>
  </v-row>
</template>

<script>
import UserModel from '@/models/Users/UserModel'
import selectDSP from '@/components/KeystoneToolsComponents/SelectDSP.vue'

export default {
  name: 'UserUiPanel.vue',
  props: {
    value: {
      type: Array
    },
    selectedId: {
      type: Number
    },
    currentUserRoles: {
      type: Array
    },
    isBucketEdit: {
      type: Boolean
    },
    currentRoles: {
      type: Array
    },
    isLoadingRole: {
      type: Boolean
    },
    selected: {
      type: Array
    },
    isProcessingAdd: {
      type: Boolean
    },
    isProcessingDelete: {
      type: Boolean
    },
    updatedUser: {
      type: UserModel
    }
  },
  data: function () {
    return {
      searchRole: '',
      headersRole: [
        { text: 'role', value: 'role' }
      ],
      rightsToAdd: [],
      selectedRights: []
    }
  },
  mounted: function () {
    this.rightsToAdd = this.value
  },
  methods: {
    emitProcessAddRights () {
      this.$emit('add-rights')
    },
    emitUpdateStatus () {
      this.$emit('open-update-status')
    },
    deleteRoles () {
      this.$emit('delete-rights', this.selectedRights.map(item => item.role))
      this.selectedRights = []
    },
    isDisabledActionCheckBox (role) {
      return this.currentUserRoles.indexOf(role) === -1 || this.roleIsInheritedFromStatus(role)
    },
    roleIsInheritedFromStatus (role) {
      return !!this.getRoleIsInheritedFrom(role).length
    },
    getRoleIsInheritedFrom (role) {
      const roleByType = this.$store.getters.getRoleByType
      const inheritedFrom = []
      if (this.updatedUser.isAm && roleByType.AM_RIGHTS.includes(role)) {
        inheritedFrom.push('AM_RIGHTS')
      }

      if (this.updatedUser.isSale && roleByType.SALE_RIGHTS.includes(role)) {
        inheritedFrom.push('SALE_RIGHTS')
      }

      if (this.updatedUser.isSettupper && roleByType.SETTUPPER_RIGHT.includes(role)) {
        inheritedFrom.push('SETTUPPER_RIGHTS')
      }

      if (this.updatedUser.isDebugger && roleByType.DEBUGGER_RIGHT.includes(role)) {
        inheritedFrom.push('DEBUGGER_RIGHT')
      }

      if (this.updatedUser.isAdminAndFinance && roleByType.ADMIN_AND_FINANCE_RIGHTS.includes(role)) {
        inheritedFrom.push('ADMIN_AND_FINANCE_RIGHTS')
      }
      return inheritedFrom
    }
  },
  computed: {
    displaySingleUserPanel () {
      return !this.isBucketEdit && this.selectedId
    },
    displayBucketEditPanel () {
      return this.isBucketEdit
    },
    disableUpdateStatus () {
      return this.isBucketEdit || this.selectedId === null || this.selectedId === undefined
    },
    disableAdd () {
      return this.selected.length === 0 && (this.selectedId === null || this.selectedId === undefined)
    },
    disabledDeleteRoles () {
      return this.selectedRights.length === 0 || this.selectedId === null || this.selectedId === undefined
    }
  },
  watch: {
    rightsToAdd: {
      immediate: true,
      deep: true,
      handler: function (newValue) {
        this.$emit('input', newValue)
      }
    },
    value (val) {
      if (val !== this.rightsToAdd) {
        this.rightsToAdd = val
      }
    }
  }
}
</script>

<style scoped>
  .progress-div {
    width: 100%;
  }

  .table-role-overflow {
    overflow: auto;
    height: 22em;
  }

  .user-panel-layout {
    border-left: 1px solid #d7d4d4;
    height: 100%;
  }

  .selected-bucket-div {
    overflow: auto;
    height: 19em;
  }
</style>
