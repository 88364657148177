
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UserRightByRole',
  data: function () {
    return {
      surcouche: [
        { name: 'All features - Read right', am: 'Yes', sales: 'No', debugger: 'Yes', settupper: 'Yes', adminFinance: 'No' },
        { name: 'Basic features - Write right', am: 'Yes', sales: 'No', debugger: 'Yes', settupper: 'Yes', adminFinance: 'No' },
        { name: 'Advanced features - Write right', am: 'No', sales: 'No', debugger: 'Yes', settupper: 'Yes', adminFinance: 'No' }
      ],
      alerting: [
        { name: 'Read right', am: 'Yes', sales: 'No', debugger: 'Yes', settupper: 'Yes', adminFinance: 'No' },
        { name: 'Write right', am: 'Yes', sales: 'No', debugger: 'Yes', settupper: 'Yes', adminFinance: 'No' }
      ],
      demo: [
        { name: 'Edit rights', am: 'Yes', sales: 'Yes', debugger: 'Yes', settupper: 'Yes', adminFinance: 'No' }
      ],
      holdingLevel: [
        { name: 'All features - Read right', am: 'Yes', sales: 'Yes', debugger: 'Yes', settupper: 'Yes', adminFinance: 'Yes' },
        { name: 'Basic feature - Edit right', am: 'No', sales: 'No', debugger: 'No', settupper: 'Yes', adminFinance: 'Yes' },
        { name: 'Advanced features - Edit right', am: 'No', sales: 'No', debugger: 'No', settupper: 'Yes', adminFinance: 'No' }
      ],
      companyLevel: [
        { name: 'All features - Read right', am: 'Yes', sales: 'Yes', debugger: 'Yes', settupper: 'Yes', adminFinance: 'Yes' },
        { name: 'Basic feature - Edit right', am: 'No', sales: 'No', debugger: 'No', settupper: 'Yes', adminFinance: 'Yes' },
        { name: 'Advanced features - Edit right', am: 'No', sales: 'No', debugger: 'No', settupper: 'Yes', adminFinance: 'No' }
      ],
      seatLevel: [
        { name: 'All features - Read right', am: 'Yes', sales: 'Yes', debugger: 'Yes', settupper: 'Yes', adminFinance: 'Yes' },
        { name: 'Basic feature - Edit right', am: 'Yes', sales: 'No', debugger: 'Yes', settupper: 'Yes', adminFinance: 'No' },
        { name: 'Advanced features - Edit right', am: 'No', sales: 'No', debugger: 'No', settupper: 'Yes', adminFinance: 'No' }
      ],
      memberLevel: [
        { name: 'All features - Read right', am: 'Yes', sales: 'Yes', debugger: 'Yes', settupper: 'Yes', adminFinance: 'Yes' },
        { name: 'Basic feature - Edit right', am: 'Yes', sales: 'No', debugger: 'Yes', settupper: 'Yes', adminFinance: 'No' },
        { name: 'Advanced features - Edit right', am: 'No', sales: 'No', debugger: 'No', settupper: 'Yes', adminFinance: 'No' }
      ],
      billingEntityLevel: [
        { name: 'All features - Read right', am: 'Yes', sales: 'Yes', debugger: 'Yes', settupper: 'Yes', adminFinance: 'Yes' },
        { name: 'Basic feature - Edit right', am: 'No', sales: 'No', debugger: 'No', settupper: 'Yes', adminFinance: 'Yes' },
        { name: 'Advanced features - Edit right', am: 'No', sales: 'No', debugger: 'No', settupper: 'No', adminFinance: 'Yes' }
      ]
    }
  },
  methods: {
    emitClose () {
      this.$emit('close')
    }
  }
})
