<template>
  <v-row class="pa-1">
    <v-col>
      <v-text-field
        label="search"
        v-model="searchUser">

      </v-text-field>
    </v-col>
    <v-col>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            @click="emitRefresh()"
            text
            icon
            v-on="on"
          >
            <v-icon>refresh</v-icon>
          </v-btn>
        </template>
        <span>Refresh the data</span>
      </v-tooltip>
    </v-col>
    <v-col cols="auto" class="text-center justify-space-between">
      <v-btn class="ma-2" color="primary" @click="emitOpenNewUser">
        New
      </v-btn>

      <v-btn class="ma-2" cols="auto" color="primary" @click="emitOpenRoleUser">
        {{roleUsersLabel}}
      </v-btn>

      <!--<v-btn class="ma-2" cols="auto" color="primary" @click="emitOpenRoleByType">
        Role by type
      </v-btn> -->

      <v-btn class="ma-2" cols="auto" color="primary" @click="emitOpenRightByRole">
        Right by roles
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'UserUiToolbar.vue',
  props: {
    value: {
      type: String
    }
  },
  data: function () {
    return {
      searchUser: ''
    }
  },
  mounted: function () {
    this.searchUser = this.value
  },
  methods: {
    emitRefresh () {
      this.$emit('refresh')
    },
    emitOpenNewUser () {
      this.$emit('open-new-user')
    },
    emitOpenRoleUser () {
      this.$emit('open-role-users')
    },
    emitOpenRoleByType () {
      this.$emit('open-role-by-type')
    },
    emitOpenRightByRole () {
      this.$emit('open-right-by-role')
    }
  },
  computed: {
    roleUsersLabel () {
      return 'Role <> Users'
    }
  },
  watch: {
    searchUser: {
      immediate: true,
      deep: true,
      handler: function (newValue) {
        this.$emit('input', newValue)
      }
    },
    value (val) {
      if (val !== this.searchUser) {
        this.searchUser = val
      }
    }
  }
}
</script>

<style scoped>

</style>
